<template>
  <div class="container loveLetter-border" id="container">
    <img class="one" :src="data[0].path" alt="" mode="width-fixed" />
    <div class="two">
      <span :style="getStyle(data[1])">{{ data[1].content }}</span>
    </div>
    <div class="three">
      <img src="@/assets/images/love-letter/img3.png" alt="" />
    </div>
    <div class="four changeHeight">
      <img :src="data[2].path" alt="" class="img1" />
      <img :src="data[3].path" alt="" class="img2" />
      <div></div>
      <div class="c"></div>
    </div>
    <div class="five">
      <div class="word">
        <span :style="getStyle(data[4])">{{ data[4].content }}</span>
      </div>
      <div class="img">
        <img src="@/assets/images/love-letter/img5.png" alt="" />
      </div>
    </div>
    <div class="six">
      <img src="@/assets/images/love-letter/img6.png" alt="" />
    </div>
    <div class="seven">
      <div class="up">
        <img :src="data[5].path" alt="" />
      </div>
      <div class="down">
        <div class="left">
          <img :src="data[6].path" alt="" />
        </div>
        <div class="right">
          <img :src="data[7].path" alt="" />
        </div>
      </div>
    </div>
    <div class="eight">
      <span :style="getStyle(data[8])">{{ data[8].content }}</span>
    </div>
    <div class="nine">
      <img src="@/assets/images/love-letter/img9.png" alt="" />
    </div>
    <img class="ten" :src="data[9].path" alt="" />
    <div class="eleven">
      <div class="word">
        <span :style="getStyle(data[10])">{{ data[10].content }}</span>
      </div>
      <div class="img">
        <img src="@/assets/images/love-letter/img11.png" alt="" />
      </div>
    </div>
    <div class="twelve">
      <img src="@/assets/images/love-letter/img12.png" alt="" />
    </div>
    <div class="thirteen">
      <img :src="data[11].path" alt="" class="img1" />
      <div class="c"></div>
      <img :src="data[12].path" alt="" class="img2" />
    </div>
    <div class="fourteen">
      <span :style="getStyle(data[13])">{{ data[13].content }}</span>
      <span>&nbsp;</span>
      <span :style="getStyle(data[14])">{{ data[14].content }}</span>
    </div>
    <div class="fifteen">
      <img src="@/assets/images/love-letter/img15.png" alt="" />
    </div>
    <div class="sixteen">
      <div class="up">
        <div class="img">
          <img :src="data[15].path" alt="" />
        </div>
        <div class="background"></div>
      </div>
      <div class="down">
        <div class="img">
          <img :src="data[16].path" alt="" />
        </div>
        <div class="background"></div>
      </div>
    </div>
    <div class="seventeen">
      <span :style="getStyle(data[17])">{{ data[17].content }}</span>
    </div>
    <div class="eighteen">
      <img src="@/assets/images/love-letter/img18.png" alt="" />
    </div>
    <div class="nineteen">
      <img :src="data[18].path" alt="" />
    </div>
    <div class="twenty">
      <span :style="getStyle(data[19])">{{ data[19].content }}</span>
    </div>
    <div class="twenty-one">
      <img src="@/assets/images/love-letter/img21.png" alt="" />
    </div>
  </div>
</template>

<script>
import scroll from "@/mixins/scroll";
import loveLetterData from "@/assets/data/loveLetter";
export default {
  mixins: [scroll],
  name: "LoveLetter",
  props: {
    list: {
      type: Array,
    },
    preview: {
      type: Boolean,
    },
  },
  data() {
    return {
      data: JSON.parse(JSON.stringify(loveLetterData)),
    };
  },
  methods: {
    getStyle(data) {
      let percent =
        this.$route.name == "display" ||
        this.preview ||
        this.$route.name == "finished"
          ? 1
          : 0.6;
      return {
        fontFamily: data.fontFamily,
        fontSize: data.fontSize * percent + "px",
        color: data.fontColor,
        lineHeight: data.fontLineHeight * percent + "px",
        letterSpacing: data.fontSpacing * percent + "px",
        whiteSpace: "pre-wrap",
      };
    },
  },
  watch: {
    list: {
      immediate: true,
      handler() {
        if (this.list) {
          this.data = this.list;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  display: block;
  width: 100%;
  text-align: center;
  line-height: 32px;
}
.container {
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-y: auto;
  background-color: #fff;
  box-sizing: border-box;
}
.one {
  width: 80%;
  background-color: #ffeede;
  border-radius: 800px 800px 0 0;
  padding: 16px 14px 17px 14px;
  margin: 50px auto 0;
}
.two {
  margin-top: 10%;
}
.three {
  margin-top: 10%;
  width: 100%;
  img {
    width: 100%;
  }
}
.four {
  width: 80%;
  height: 30%;
  margin: 15% auto;
  .img1 {
    float: left;
    width: 75%;
  }
  .img2 {
    width: 75%;
    margin-top: 12%;
    float: right;
  }
  div {
    height: 1px;
    width: 1px;
  }
  .c {
    height: 100%;
    margin: 25% 0 0 15%;
    background-color: #ffeede;
    width: 72%;
  }
}
.five {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-top: 20px;
  .word {
    width: 50%;
    text-align: left;
    margin-top: 20px;
  }
  .img {
    width: 40%;
    flex: 1;
    img {
      width: 100%;
    }
  }
}
.six {
  width: 100%;
  margin-top: 30px;
  img {
    width: 100%;
  }
}
.seven {
  width: 80%;
  margin: 10% auto 0;
  background-color: #ffeede;
  box-sizing: border-box;
  padding: 2%;
  .up {
    width: 95%;
    margin: 2% auto;
  }
  .down {
    width: 95%;
    margin: 5% auto;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .left {
      width: 54%;
    }
    .right {
      width: 40%;
      border-radius: 400px 400px 0 0;
      overflow: hidden;
    }
  }
  img {
    width: 100%;
  }
}
.eight {
  margin-top: 10%;
}
.nine {
  width: 100%;
  margin: 15% auto;
  img {
    width: 100%;
  }
}
.ten {
  width: 85%;
  transform: translate(4% 4%);
  box-shadow: -15px -15px 0 0 #ffeede;
}
.eleven {
  position: relative;
  width: 80%;
  margin: 20% auto 15%;
  .word {
    text-align: left;
  }
  .img {
    width: 48%;
    position: absolute;
    top: 30%;
    right: 0;
    img {
      width: 100%;
      // opacity: 0.3;
    }
    z-index: 2;
  }
}
.twelve {
  width: 100%;
  img {
    width: 100%;
  }
}
.thirteen {
  position: relative;
  width: 88%;
  margin: 10% auto;
  display: flex;
  flex-direction: column;

  img{
    position: relative;
    z-index: 1;
  }

  .img1 {
    width: 60%;
    align-self: flex-end;
    margin-bottom: 5%;
  }
  .img2 {
    width: 60%;
  }

  .c{
    position: absolute;
    width: 50%;
    height: 50%;
    background: #ffeede;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 0;
  }
}
.fourteen {
  margin-top: 5%;
}
.fifteen {
  img {
    width: 40%;
  }
}
.sixteen {
  width: 85%;
  margin: 6% auto 0;
  display: flex;
  flex-wrap: wrap;
  .up {
    width: 86%;
    .img {
      width: 100%;
      float: left;
      margin: 5% 0 0 5%;
    }
    .background {
      width: 100%;
      height: 90%;
      background-color: #ffeede;
    }
  }
  .down {
    width: 86%;
    margin: 8% 0 0 16%;
    .img {
      width: 100%;
      float: left;
      margin: -5% 0 0 -5%;
    }
    .background {
      width: 100%;
      height: 110%;
      background-color: #ffeede;
    }
  }
  img {
    width: 100%;
  }
}
.seventeen {
  margin-top: 10%;
}
.eighteen {
  margin-top: 10%;
  img {
    width: 100%;
  }
}
.nineteen {
  margin-top: 10%;
  img {
    width: 80%;
  }
}
.twenty {
  margin-top: 10%;
}
.twenty-one {
  margin-top: 10%;
  padding-bottom: 20px;
  img {
    width: 85%;
  }
}
</style>